import React, { useEffect, useState, useCallback } from "react";
import { useStoreActions, useStoreState } from "../store/hooks";
import { FALLACIES, FALLACY_NAMES } from "../store/constants";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";

const WIRED_CARDS_STYLES = {
  BEFORE_ANSWER: "card-highlightable",
  CORRECT_ANSWER: "card-correct-answer",
  WRONG_ANSWER: "card-wrong-answer",
};

export function Quiz() {
  const [popoverList, setPopoverList] = useState([false, false, false, false]);
  const history = useHistory();
  const handleFinishClick = useCallback(() => history.push("/finished/"), [
    history,
  ]);

  const toggle = (i) => {
    const popoverListCopy = [false, false, false, false];
    if (popoverList[i]) {
      setPopoverList(popoverListCopy);
      return;
    }
    popoverListCopy[i] = !popoverListCopy[i];
    setPopoverList(popoverListCopy);
  };
  const [showNextQuestionButton, updateShowNextQuestionButton] = useState(
    false
  );
  const { answerQuestion, generateQuiz, setQuestionIndex } = useStoreActions(
    (a) => a
  );
  const { quizQuestions, score, totalQuestions, questionIndex } = useStoreState(
    (s) => s
  );

  useEffect(() => {
    if (!quizQuestions.length) {
      generateQuiz();
    }
  }, [quizQuestions, generateQuiz]);

  const handleNextQuestionClick = () => {
    setQuestionIndex(questionIndex + 1);
    updateShowNextQuestionButton(false);
    setPopoverList([false, false, false, false]);
  };

  const handleClickAnswer = (a) => {
    if (showNextQuestionButton) {
      return;
    }
    answerQuestion({ question: questionIndex, answer: a });
    updateShowNextQuestionButton(true);
  };

  const currentQuestion = quizQuestions[questionIndex];

  const numberOfQuestionsAnswered = score[1];
  const allQuestionsAnswered = numberOfQuestionsAnswered === totalQuestions;
  if (!currentQuestion) {
    return <></>;
  }

  const { question, answers, correctAnswer } = currentQuestion;

  const createAnswerCardCSS = (answer) => {
    if (!showNextQuestionButton) {
      return WIRED_CARDS_STYLES.BEFORE_ANSWER;
    }
    return "";
  };

  const createFill = (answer) => {
    if (!showNextQuestionButton) {
      return "";
    }

    if (answer === correctAnswer) {
      return "lightgreen";
    } else {
      return "#f9adad";
    }
  };

  const theQuestion = FALLACIES[question[0]].examples[question[1]];
  const isQuestionMultipleLines = Array.isArray(theQuestion);
  return (
    <>
      <h2>The Fallacy:</h2>
      <wired-card elevation={totalQuestions - questionIndex}>
        {isQuestionMultipleLines
          ? theQuestion.map((question) => <p>{question}</p>)
          : theQuestion}
      </wired-card>
      <h2 style={{ marginTop: "24px" }}>The Answers:</h2>
      <div className="answer-container">
        {answers.map((answer, i) => (
          <div className="answer-wrapper">
            <wired-card
              key={answer}
              onClick={() => handleClickAnswer(answer)}
              class={createAnswerCardCSS(answer)}
              fill={createFill(answer)}
              elevation="1"
            >
              <span>{FALLACY_NAMES[answer]}</span>
              {showNextQuestionButton && (
                <>
                  <span className="info-box" id={`Popover${i}`}>
                    <AiOutlineInfoCircle />
                  </span>
                  <Popover
                    popperClassName="something"
                    placement="auto"
                    isOpen={popoverList[i]}
                    target={`Popover${i}`}
                    toggle={() => toggle(i)}
                  >
                    <PopoverHeader>{FALLACY_NAMES[answer]}</PopoverHeader>
                    <PopoverBody>
                      <span style={{ fontWeight: 600 }}>Definition: </span>
                      {FALLACIES[answer].definition}

                      {FALLACIES[answer].subDefinition ? (
                        <div style={{ marginTop: "16px" }}>
                          <span style={{ fontWeight: 600 }}>
                            Additional Info:{" "}
                          </span>{" "}
                          {FALLACIES[answer].subDefinition}
                        </div>
                      ) : (
                        ""
                      )}
                    </PopoverBody>
                  </Popover>
                </>
              )}
            </wired-card>
          </div>
        ))}
      </div>
      {showNextQuestionButton && !allQuestionsAnswered && (
        <wired-card
          onClick={handleNextQuestionClick}
          class="next-button"
          elevation="1"
        >
          NEXT QUESTION
        </wired-card>
      )}
      {showNextQuestionButton && allQuestionsAnswered && (
        <wired-card
          onClick={handleFinishClick}
          class="next-button"
          elevation="1"
        >
          FINISH
        </wired-card>
      )}
    </>
  );
}
