import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

export function Home() {
  const history = useHistory();
  const handleQuizClick = useCallback(() => history.push("/quiz/"), [history]);
  const handleLearnClick = useCallback(() => history.push("/fallacies/"), [
    history,
  ]);

  return (
    <div className="centered-container">
      <p>
        A quiz game that helps you learn about thoughts that are Logical
        Fallacies
      </p>

      <p>So you can better watch for them in your daily life!</p>
      <wired-button onClick={handleQuizClick}> Start Quiz </wired-button>
      <wired-button onClick={handleLearnClick}>
        {" "}
        Learn About Fallacies{" "}
      </wired-button>
    </div>
  );
}
