import { createStore, action, Action, computed, Computed } from "easy-peasy";
import { selectAnswers, selectQuestion } from "./utils";

export type QuizQuestions = {
  question: [number, number];
  answers: number[];
  correctAnswer: number;
  answeredCorrectly: boolean;
}[];

export interface StoreModel {
  quizQuestions: QuizQuestions;
  questionIndex: number;
  generateQuiz: Action<StoreModel>;
  resetQuiz: Action<StoreModel>;
  answerQuestion: Action<StoreModel, { question: number; answer: number }>;
  setQuestionIndex: Action<StoreModel, number>;
  score: Computed<StoreModel, [number, number]>;
  totalQuestions: Computed<StoreModel, number>;
}

export const store = createStore<StoreModel>({
  quizQuestions: [],
  questionIndex: 0,
  generateQuiz: action((state) => {
    let quizQuestions: any = [];
    for (let i = 0; i < 5; i++) {
      const question = selectQuestion(quizQuestions);
      const answers = selectAnswers(4, question[0]);
      const correctAnswer = question[0];
      quizQuestions.push({
        question,
        answers,
        correctAnswer,
      });
    }
    state.quizQuestions = quizQuestions;
  }),
  resetQuiz: action((state) => {
    state.quizQuestions = [];
    state.questionIndex = 0;
  }),
  answerQuestion: action((state, { question, answer }) => {
    const currentQuestion = state.quizQuestions[question];
    currentQuestion.answeredCorrectly =
      currentQuestion.correctAnswer === answer;
  }),
  setQuestionIndex: action((state, index) => {
    state.questionIndex = index;
  }),
  score: computed((state) => {
    const correctAnswers = state.quizQuestions.filter((question) => {
      return question.answeredCorrectly;
    });
    const totalAnswered = state.quizQuestions.filter((question) => {
      return typeof question.answeredCorrectly !== "undefined";
    });
    return [correctAnswers.length, totalAnswered.length];
  }),
  totalQuestions: computed((state) => {
    return state.quizQuestions.length;
  }),
});
