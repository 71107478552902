import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  RiEmotionUnhappyLine,
  RiEmotionSadLine,
  RiEmotionNormalLine,
  RiEmotionHappyLine,
  RiEmotionLine,
  RiEmotionLaughLine,
} from "react-icons/ri";
import { useStoreActions, useStoreState } from "../store/hooks";

export const RESULT_TEXT = [
  "You really don't know your fallacies! Keep learning!",
  "Oh my, maybe try again!",
  "You can do better! Keep trying!",
  "Good job! You know some fallacies! Try again to learn more!",
  "Great Job! You knew most of the fallacies!",
  "Amazing! You're a fallacy recognition champion!",
];

const RESULT_IMAGE = [
  <RiEmotionUnhappyLine />,
  <RiEmotionSadLine />,
  <RiEmotionNormalLine />,
  <RiEmotionHappyLine />,
  <RiEmotionLine />,
  <RiEmotionLaughLine />,
];

export function FinalScore() {
  const { score } = useStoreState((s) => s);
  const history = useHistory();

  const { resetQuiz } = useStoreActions((a) => a);

  useEffect(() => {
    if (score[1] === 0) {
      history.push("/");
    }
  });

  const handleQuizClick = useCallback(() => {
    resetQuiz();
    history.push("/quiz/");
  }, [history, resetQuiz]);

  return (
    <div className="centered-container">
      <h3>
        Final Score: {score[0]} / {score[1]}
      </h3>

      <p>
        {RESULT_TEXT[score[0]]}
        {RESULT_IMAGE[score[0]]}{" "}
      </p>
      <wired-button onClick={handleQuizClick}> Play Again </wired-button>
    </div>
  );
}
