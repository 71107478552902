import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";

import { useLocation } from "react-router-dom";

import { useStoreState } from "../store/hooks";

export const NavbarT = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const toggle = () => setIsOpen(!isOpen);

  const isQuiz = location.pathname.toLowerCase() === "/quiz/";
  const isFallaciesDefinitions =
    location.pathname.toLowerCase() === "/fallacies/";

  const { score } = useStoreState((s) => s);

  return (
    <div>
      <Navbar color="white" light expand="md">
        <NavbarBrand href="/">My Fallacies</NavbarBrand>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink className={isQuiz ? "text-underline" : ""} href="/quiz/">
                Quiz
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={isFallaciesDefinitions ? "text-underline" : ""}
                href="/fallacies/"
              >
                Fallacy Definitions
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        {isQuiz && (
          <div>
            <NavbarText>
              Score: {score[0]} / {score[1]}
            </NavbarText>
          </div>
        )}

        <NavbarToggler onClick={toggle} />
      </Navbar>
    </div>
  );
};
