import React from "react";
import "./App.css";
import { StoreProvider } from "easy-peasy";
import { store } from "./store/store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from "./screens/home";
import { Quiz } from "./screens/quiz";
import { Fallacies } from "./screens/fallacies";
import { FinalScore } from "./screens/final-score";
import { NavbarT } from "./components/navbar";

// Load wired-elements at base component
import "wired-elements";

function App() {
  return (
    <StoreProvider store={store}>
      <Router>
        <NavbarT />
        <div className="nav-space" />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/quiz">
            <Quiz />
          </Route>
          <Route path="/fallacies">
            <Fallacies />
          </Route>
          <Route path="/finished">
            <FinalScore />
          </Route>
        </Switch>
      </Router>
    </StoreProvider>
  );
}

export default App;
