type fallacies = {
  name: string;
  definition: string;
  subDefinition: string;
  examples: (string | string[])[];
}[];

export const FALLACIES: fallacies = [
  {
    name: "Ad Hominem",
    definition: "Attacking the person and not the argument.",
    subDefinition:
      "Ad hominem is Latin for against the man.” Instead of advancing good sound reasoning, an ad hominem replaces logical argumentation with attack-language unrelated to the truth of the matter.",
    examples: [
      "My friend John likes to eat instant noodles.  Clearly he’s unfit to be a food critic.",
      "I don't even have a library card.  How can I say I'm someone who likes to read?",
      "Maybe I should look at all my failures regarding this project isn't going well.",
      "Perhaps if I weren't born Christian, I would be able see this issue differently.",
      "They wouldn't understand since they've never had to struggle.",
      "This is a female issue. As a man, how can I have an opinion about this?",
      "I didn't even finish high school. How could I possibly know about this?",
      "Man... I'm clearly just too young to understand.",
      "I should be able to run faster, I guess I'm just too fat",
      "Why didn't I get my work done, I must be lazy.",
    ],
  },
  {
    name: "Strawman Argument",
    definition: "Attacking a view that is not represented by the person",
    subDefinition:
      "The Strawman argument is aptly named after a harmless, lifeless, scarecrow. In the strawman argument, someone attacks a position the opponent doesn’t really hold. Instead of contending with the actual argument, he or she attacks the equivalent of a lifeless bundle of straw, an easily defeated effigy, which the opponent never intended upon defending anyway.",
    examples: [
      "Creationists are pretending to promote a scientific theory, but they are really just trying to introduce their religious teachings into the classroom.",
      "My biology teacher said all things evolve, but my friend said 'He just doesn't believe that humans came from bugs'.  Should I believe my biology teacher?",
      "I feel like some of Donald Trump's positions have merit.  But if I believe that, I support racism.",
      "I think teens should be taught about contraception methods so they can practice safe sex.  But sex education will give kids license to have sex with no consequences.",
      "I like this guy, but I think he could be anti-women because he loves pornography, thus he is in favor of the sexual and violent exploitation of women",
      "How can you support pro-choice? Do you support murderers?",
    ],
  },
  {
    name: "Appeal to Ignorance",
    definition:
      "Occurs when you argue that your conclusion must be true, because there is no evidence against it.",
    subDefinition:
      "This logical fallacy occurs when someone claims that a conclusion must be true if it hasn’t been proven false, or false if it hasn’t been proven true.",
    examples: [
      `I guess I should sleep with him, when I asked him "Why should I?".  He responded: "Why shouldn't I?".  I don't have an answer for that.`,
      `Since he said I'm stupid, I guess I must be stupid`,
      `There's really no proof that UFO's have not visited the Earth, so it makes sense to believe in them.`,
      `I guess I didn't get the job. They never called me back.`,
      `She hasn't explicity said she doesn't like me, so she's likely interested. I should call her up!`,
      `My friend is so skeptical of ESP.  But can they prove it doesn't exist?`,
      `I was doing fine leading the group; no one complained.`,
    ],
  },

  {
    name: "False Dilemma",
    definition:
      "When you reason from an either-or position and you haven't considered all relevant possibilities you commit the fallacy of false dilemma",
    subDefinition:
      "Sometimes the choices are between one thing, the other thing, or both things together (they don’t exclude each other). Sometimes there is a whole range of options, three, four, five, or a hundred and forty-five.",
    examples: [
      `I either love or hate my partner`,
      `I can't tell if this new person at the office is my friend or be my enemy.`,
      `hmmm, am I a Republican or a Democrat?`,
      `When I die, I'll either go to heaven or hell`,
      `Good students will study and learn without the threat of an exam, and bad students won't study and learn even with the threat of an exam. So, exams serve no purpose`,
      `Since there is nothing good on TV tonight, I will just have to get drunk.`,
      `Math is so difficult! Obviously great mathematicians are born, and not made.`,
      `There's jocks on the left, and rebels on the right. Which one of these groups do I want to hang out with in the future?`,
      `I either believe in capitialism or I don't.`,
      `I either need to buy this book right now, or I know that I don't care about reading`,
      `Drink water every day and be healthy, or continue to drink sodas and be unhealthy. Those are the only options.`,
      `I either support Hillary Clinton for President or I guess I don't believe in women's rights.`,
      `Going to school makes me smarter, or it makes me stupider.`,
      `The only way I can get stronger is if I run or if I lift weights`,
      `The animal shelter is a place that values animal rights and never euthanizes animals, or the animal shelter is a place that kills innocent animals.`,
      `I either like kids or you don't.`,
    ],
  },
  {
    name: "Slippery Slope Fallacy",
    definition:
      "An argument that suggests taking a minor action will lead to major and sometimes ludicrous consequences.",
    subDefinition:
      "The slippery slope fallacy suggests that unlikely or ridiculous outcomes are likely when there’s just not enough evidence to think so.",
    examples: [
      `If I allow the our kids to pick the movie this time, they are going to expect to be able to choose the school they go to or the doctors they visit.`,
      `We need to stop allowing colleges to increase tuition every year. The next thing we know, it's going to cost more to attend college for one semester than it is to buy a new home!`,
      `If I miss my workout, I'll be breaking my routine.  The next thing I'll be be eating a giant pizza, and will put on 50 pounds`,
      `If I accept gay marriage is okay, the next thing we'll be doing as a society is marrying dogs, cats, and pigs.`,
      `If you break your diet and have one cookie tonight, you will just want to eat 10 cookies tomorrow, and before you know it, you will have gained back the 15 pounds you lost.`,
      `If we give in every time our baby cries, he will always pitch a fit to get what he wants, and he will end up in prison because we never set limits.`,
      `We cannot unlock our child from her room, because if we do, she will want to roam around the house.  If we let her roam the house, she will want to roam the neighborhood.  If she roams the neighborhood, she will get picked up by a stranger in a van, who will sell her in a sex slavery ring in some other country.  Therefore, we should keep her locked up in her room.`,
      `"We can't allow terminal patients to die without doing everything to save them. If we were to allow that, next we would be permitting euthanasia, and then we would start killing people outright whenever we thought they were no longer valuable to society."`,
      `"Laws prohibiting the possession of handguns are the first step toward robbing us of all our civil liberties!"`,
    ],
  },
  {
    name: "Circular Argument",
    definition: "Restating your point instead of proving it.",
    subDefinition:
      "Circular Argument can be easy to spot because both sides of the argument are essentially making the same point.",
    examples: [
      `If the bible is true God exists, and, if God exists the bible is true.`,
      `Everyone hates me, because I'm so unlikeable.`,
      `This drawing is going to turn out bad because I'm bad at drawing`,
      `There is no use in speaking up, because speaking up is useless`,
      `This date is going to be bad, because I'm bad at dating`,
      `I can't finish this project because this project will never be finished.`,
      `I must obey the law, because it's illegal to break the law.`,
      `The new Scorsese film is well directed, because Scorsese is a wonderful director.`,
      `America is the best place to live, because it's better than any other country.`,
      `Violent video games cause teens to be violent, because violent teens play violent video games.`,
      `There's so much fake news out there, becuase all news channels make fake news these days`,
    ],
  },
  {
    name: "Hasty Generalization",
    definition:
      "Not studied enough examples for such a conclusion Restating your point instead of proving it.",
    subDefinition:
      "The hasty generalization fallacy is sometimes called the over-generalization fallacy. It is basically making a claim based on evidence that it is just too small. Essentially, you can't make a claim and say that something is true if you have only an example or two as evidence.",
    examples: [
      `"The last two men in my life has Affairs. Therefore, men are likely to cheat.”`,
      `"I read that an environmental group illegally blocked loggers and workers at a nuclear plant. Therefore, environmentalists are radicals who take the law into their own hands.`,
      `I just arrived in this country and the first 2 local people I met were so rude. Everyone in this country must be unfriendly!”`,
      `I asked five people in the street what is their favorite color, and four of them said blue. Therefore, 80% of the population prefer blue over any other color.”`,
      `My dad has smoked 2 packs of cigarettes every day for 20 years, and he doesn’t have any health problems. Smoking can’t be dangerous!”`,
      `There aren’t any good restaurants in Canada; I tried two and they were both horrible.”`,
      `This exercise program helped several people to lose weight. Therefore, this program will be effective for everyone.”`,
      `Did you see that woman just run a red light? Women are awful drivers.”`,
    ],
  },
  {
    name: "Red Herring",
    definition: "Irrelevant and distracting from the main argument",
    subDefinition:
      "A deliberate diversion of attention with the intention of trying to abandon the original argument.",
    examples: [
      `"I'm so hurt that Todd broke up with me.  But I should just think of all the starving children in Africa, my problems are so compared to that."`,
      `"It's really hard to make a living on my salary! But then again my Dad said at my age, he made only $40 per week"`,
      `"My doctor says I should be eating healthy, but this junk food is on sale!"`,
      `"I don't need to give my employee rasies.  We've worked really hard to make a positive work environment."`,
      `"I need more money to support myself.  Money will definitely bring me happiness."`,
      `"I promised my wife I'd clean the garage yeterday, but playing video games is way more fun!"`,
      `"In conversation, in arguing against gay marriage rights - "I don't think that there should be marriage among homosexuals. Anyway, taxes on married people are high. I think that taxes on the married are just ridiculous."`,
      `"Unfortunately, I think I'm going to have fire 5% of my staff. It's important the product we create is exceptionally flawless!"`,
      `"Eating cotton candy will give me a stomach ache, but I love going to county fairs!"`,
      `"I am pretty sure that evolution is not a very good explanation for human life. It's absurd that people would suggest that that I came from a monkey."`,
      `"When you start saying things to me like I need to eat healthier or get more exercise, that says to me that you think I'm fat. I like me, and I like the way I look and more people should have better self esteem."`,
      `"Damn, I went over the phone bill limit, but at least I did well on my math test.`,
    ],
  },
  {
    name: "Questionable Cause",
    definition: "A correlation does not necessarily equal causation",
    subDefinition:
      "This fallacy occurs when a causal connection is assumed without proof. All too often claims to a causal connection are based on a mere correlation. The occurrence of one event after the other or the occurrence of events simultaneously is not proof of a causal connection.",
    examples: [
      `"Last time I wore these red pants I got hit by a car. It must be because they bring bad luck."`,
      `"Whenever I take this pill my cough clears up within a week, so this pill is very effective in curing coughs."`,
      `"God definitely exists because after I go to church I feel a lot happier"`,
      `"I never had an issue with the elevator until the new neighbours moved into the building.  They're definitely the problem."`,
      `"I had a very successful business, then I started putting on weight, and my business went down the drain!"`,
      `"When the lakers win, the stock Market usually does well the next day. I hope the lakers win tonight!"`,
      `"I'm sure that Marilyn Manson's music had something to do with those murders. They found Manson CD's in one of the murderer's private collection."`,
      `"Well, right after my wife divorced me, I couldn't get a date to save my life. There's got to be some connection there."`,
      `"My day had been going fine up to the time I walked under that ladder. After that, everything went sour."`,
      `"After women started working in this county, divorce rates went up and now an alarming number of homes in this nation are broken. It's obvious that if we value marriage as an institution, we should discourage women from working."`,
    ],
  },
  {
    name: "Sunk Costs",
    definition:
      "A cost that has already been incurred and cannot be recovered.",
    subDefinition:
      "Our tendency to follow through on an endeavor if we have already invested time, effort or money into it, whether or not the current costs outweigh the benefits.",
    examples: [
      `I should keep eating because I've already bought the food.”`,
      `We should keep watching this terrible movie because we've already watched an hour of it already.”`,
      `I should continue dating someone bad for me because I’ve already invested so much in them.”`,
      "I feel really sick, but I've already driven an 45 minutes to see my Mom, I should still visit because I'm only 15 minutes away.",
      "I've pre-paid $400 for 7 tutoring lessons, but even after 4 lessons, I haven't learned a thing.  I should probably go to the remaining 3 becuase I've already paid.",
      "This hardwood floor is terrible, but I should probably use it because I've already bought it",
    ],
  },
  {
    name: "Appeal to Authority",
    definition:
      "Make the case for something because someone with authority endorses the action or idea.",
    subDefinition:
      "Insisting that a claim is true simply because a valid authority or expert on the issue said it was true, without any other supporting evidence offered.",
    examples: [
      "Peace is the best strategy because Biden said so.",
      "I should eat vitamins because Terry Crews said they're important!",
      "God does not exist because Stephen Hawking said so.",
      "God exists because the Pope and Francis Collins said so.",
      "Psychotherapy is rubbish because my life coach said so.",
    ],
  },
  {
    name: "Bandwagon Fallacy",
    definition:
      "The bandwagon fallacy describes believing something is true or acceptable only because it is popular",
    subDefinition:
      "We believe a group of people must be better informed than we are as individuals so we are quick to change our minds if the majority of people all believe the same thing.",
    examples: [
      "I should probably start keto, it seems everyone is doing it!",
      "Because everyone else goes away to college, it must be the right thing to do!",
      "I should probably buy skinny jeans, they're definitely in at the moment.",
      "Should I get an Instgram account, it seems all my friends are using it!",
      "Everyone is getting the new iPhone 12 that’s coming out this weekend, I should definitely buy it!",
    ],
  },
];

export const FALLACY_NAMES = FALLACIES.map((f) => f.name);