import React, { useEffect, useRef } from "react";
import { FALLACIES } from "../store/constants";

const convertToKey = (str) => {
  return str
    .split(" ")
    .map((s) => s.toLowerCase())
    .join("-");
};

export function Fallacies() {
  const wiredTabsRef = useRef();
  useEffect(() => {
    wiredTabsRef.current.shadowRoot.children[0].style.flexFlow = "wrap";
    wiredTabsRef.current.shadowRoot.children[1].style.marginTop = "24px";
  }, []);

  const startingKey = convertToKey(FALLACIES[0].name);

  return (
    <wired-tabs ref={wiredTabsRef} selected={startingKey}>
      {FALLACIES.map(({ name, definition, subDefinition }, i) => {
        return (
          <wired-tab key={name} name={convertToKey(name)}>
            <h4>{name}</h4>
            <p>Definition: {definition}</p>
            {subDefinition ? <p>Additional Info: {subDefinition}</p> : ""}
          </wired-tab>
        );
      })}
    </wired-tabs>
  );
}
