import { FALLACIES } from "./constants";
import type { QuizQuestions } from "./store";

const selectItem = (arr: any[]): number => {
  return Math.floor(Math.random() * arr.length);
};

export const selectQuestion = (quizQuestions: QuizQuestions) => {
  let question: number[] = [];
  const allQuestions = quizQuestions.map((qq) => qq.question);
  while (!question[0] && !question[1]) {
    const fallacyIndex = selectItem(FALLACIES);
    const questionIndex = selectItem(FALLACIES[fallacyIndex].examples);
    question = [fallacyIndex, questionIndex];
    // eslint-disable-next-line
    const foundMatch = allQuestions.find((q) => {
      return q[0] === question[0] && q[1] === question[1];
    });
    if (Boolean(foundMatch)) {
      question = [];
    }
  }
  return question;
};

export const selectAnswers = (
  numberOfAnswersToSelect: number,
  answer: number
) => {
  let answers: number[] = [answer];
  while (answers.length < numberOfAnswersToSelect) {
    const questionIndex = selectItem(FALLACIES);
    const exists = answers.some((a) => a === questionIndex);
    if (!exists) {
      answers.push(questionIndex);
    }
  }

  shuffle(answers);
  return answers;
};

function shuffle(array: any) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
